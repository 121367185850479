import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/base/layout-cases.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`{props.pageContext.frontmatter.title}`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/299f1b1a17c26feedcb304400d0273b0/07a9c/pretty-password-webdesign-showcase.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.16564417177914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADcElEQVQ4y22S60+bZRjG+5eo+7CMQgvt2wO0b9+29LR2LeU0YBtZbDY+IMFlarbFME9RE80GibJDmJVDGZbDGKcxIJAp2xDoikjoOA1N0GiMOCMxRqMffuZ9usgXP1x57jx5399zX9d9axY2M/yf5jdWSW+vM7X4EIvfQb7LiuRzYAt7ab12id7PPiGZjNPX187IcA/VL8VoTXahST1ZY3F7nfnNx8xvPRbnwtYa85sZUt9sMPXoSwzuIvJkiTyXlRzZyPLcJPy6A/88BfaAP7jwfhMt3e1o7i2nmFmYJZVJs7j6iMXVNHPLC8yvpFha/5rJ+9NEjpUieYo4eaaerv4kG5k0k3eSTE/0P9MAkeNltHTeQJMYG6S55V1GhxLcHuhgZLCLzo5W+nvjjA0luHrtEqcbT+GvCPLG5Q/4bW+P6dnP0SlmDlr15NgMQgctOlq7P0XTNXILi1+hpPYowZpSfJURDldFxalXLOTJJsw+u8jQVRWluLYSuSyI5JMpcFoocFkxuAvR2gxZoNqhEvYSrI4KBY5G8FUcIVRTirs0QIG7EIPXjsmvoHNa0Xts5Dkt5NoltHYjeQ4T+U4LOUUFWWD8di+nG2K8cqERvWLC4LKic5jEhypM8jswH3ZiCRXjKvHhLvHiUhXJqtAvi27dJ6u50tOBJjF6C6tPxhZ0ipdUC5LHhuk/kBvrEQ+2aID7M8Psfb/Gj1tLPN3JwO8/8O16ivKzDUghN62JOJrusUFy1ZWQJZGF0WOjwGPD4JORAk7MoWIsYS9yeYjBgU5WFmZIPZggPTfJ2tIXTNzpRakMc6gwfz9DtTMVlLXnwl0Rwlddgq8miv94Of4T5ciVYUzqI8VFIgq90yJqvWLO7qjduA9UL9Xu9O5CPBUhMql7bK/MsbOeEva+20xz7OU6isuC4ufaulpi9S+KoYRrosTqY+TajVy52Z61rHs2AGNAIVAVYeurWf786Qn89TP8vSuyOtFYhxLxioxVYF3jKQEvr63gzGsNAnhVHYoKzFfM5MgSH7XfYPeXXTKrS7z+5qs0vX2Oi++c51zTWUxeuwAIiw6z2AK1VvczVzZxQNLycXd8H6iVJS42f8jQ1F3Ov/cWzxkO8bxRywuSlgOmXJHz9euXGR/tYWz4JuMjPYwMJUQ9Nd5HW1szbX0J/gVHmpKZy6v9FAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "showcase image of pretty password",
            "title": "showcase image of pretty password",
            "src": "/static/299f1b1a17c26feedcb304400d0273b0/a6d36/pretty-password-webdesign-showcase.png",
            "srcSet": ["/static/299f1b1a17c26feedcb304400d0273b0/222b7/pretty-password-webdesign-showcase.png 163w", "/static/299f1b1a17c26feedcb304400d0273b0/ff46a/pretty-password-webdesign-showcase.png 325w", "/static/299f1b1a17c26feedcb304400d0273b0/a6d36/pretty-password-webdesign-showcase.png 650w", "/static/299f1b1a17c26feedcb304400d0273b0/e548f/pretty-password-webdesign-showcase.png 975w", "/static/299f1b1a17c26feedcb304400d0273b0/3c492/pretty-password-webdesign-showcase.png 1300w", "/static/299f1b1a17c26feedcb304400d0273b0/07a9c/pretty-password-webdesign-showcase.png 1440w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d12ba80206489b14b7d31b9e6fc0d5b8/471ef/pretty-password-showcase-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.190184049079754%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHElEQVQ4y33TT08TQRgG8P0o6MVk3Z2hNrUgbfff7M5utwWDVK9+Bg9evOmhJmLQQERJSfgGJp41MRDuXkAIR0zUdFtaDrRF84zZf1Da4mGyyR5++7w7zyuRsgfiuSAuB3EdEG6DcAbiWCC2CWIbIEwHYRqIVQIxiyBmAcSYg2rcg6rPQtVnoGp5qKW7kEi5DOJNQJ1r0AgeQvUE1WI0BiekpOHTYaCcgToWaIhaWpRQjVIWQYwC1BFUIr6PsZTchmIUkeUmFKsEmZVAPIZM1UWutoBshUM1CuOjXwETlHouFMfGs/oLrDY+iPraG1FfXxWvNjfE8lZDND59FI+fPsGtQh40/NDI6BL1K0hRmo7uufiyvY0fx8di0O/j7/kfnPcHGPT6EEKIlY11TOXvIGPrY6NLtFJFitIkpepyfN3dRe/sTAStFrqnpwiCAJ1uF/1eT7xce4sbM1lkHGPs1mOwkoAJeps7+La/F6Vpn7RF0ApEs9kUnW4nfCWW37/DVAoOVSlMKdHqPNKUKahwjs87O/j5+xf2Dw+xd/A9OgdHR2iftPF85TVuzuYw7ZgxOFQjiVYXQCtX0bBGc0sPoD16CP3i1FCqLUJbWkTOd6FGvRwvfAwOpSQXY3PIjg3ZZpCZBZmZkC0DsqVDYUZceJYU3rpEJTp/H6Mp0/857cc3H1aJejwqO3Vt0HCLrlnLS3AULU8o/P/WMkn5D3oPAVbS5KB1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "showcase image of pretty password",
            "title": "showcase image of pretty password",
            "src": "/static/d12ba80206489b14b7d31b9e6fc0d5b8/a6d36/pretty-password-showcase-1.png",
            "srcSet": ["/static/d12ba80206489b14b7d31b9e6fc0d5b8/222b7/pretty-password-showcase-1.png 163w", "/static/d12ba80206489b14b7d31b9e6fc0d5b8/ff46a/pretty-password-showcase-1.png 325w", "/static/d12ba80206489b14b7d31b9e6fc0d5b8/a6d36/pretty-password-showcase-1.png 650w", "/static/d12ba80206489b14b7d31b9e6fc0d5b8/e548f/pretty-password-showcase-1.png 975w", "/static/d12ba80206489b14b7d31b9e6fc0d5b8/471ef/pretty-password-showcase-1.png 1185w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We built a Chrome extension that would allow us to quickly generate secure passwords. This side was a fun side project that we used to enhance our skills build Chrome extensions. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      